/**
 * API URLS
 */
export const baseUrl = 'https://cb.appening.xyz/'
export const urls = {
  login: 'auth/api/login',
  register: 'auth/api/register',
  updateProfile: 'api/user/profile',
  sendPasswordLink: 'auth/api/forget-password/send-email',
  resetPassword: 'auth/api/forget/create-password',
  adminLogin: 'auth/api/admin/cn/login',
  createStaffPassword: 'auth/api/staff/create-password',
  resetStaffPassword: 'auth/api/staff/reset-password',
  sendPasswordLinkStaff: 'auth/api/staff/create-password/send-email',
  sendResetPasswordLinkStaff: 'auth/api/staff/reset-password/send-email',
  emailLinkVerificationadmin:'auth/api/validate-admin-email-link',
  sendProfileOtp:'api/user/send-profile-phone-otp',
  verifiyOtpProfile:'api/user/verify-profile-otp',
  application: 'api/cn/application',
  getApplicationList: 'api/cn/applications',
  getApplicationById: 'api/cn/application-by-id',
  addACHAccount: 'api/ach-account',
  verifyACHAccount: 'api/ach-account/verify',
  verifyCreditCard: 'api/ach-account/card/verify',
  deleteACHAccount: 'api/ach-account/delete',
  getACHAccount: 'api/cn/ach-account',
  achAccountList: 'api/cn/ach-account',
  achAccountListIndex: "api/ach-account-index",
  getApplicationFee: 'api/cn/application/fee',
  applicationPayment: 'api/cn/application-payment',
  searchAddress: 'api/city/address/',
  getCityList: 'api/cn/cities',
  downloadPermit: 'api/admin/download-certificate/',
  deleteOwnerOfficer: 'api/cn/ownership/remove',
  editOwnerOfficer: 'api/cn/ownership/update',
  applicationResubmit: 'api/cn/application/resubmit',
  applicationAdditionalInfo: 'api/cn/application-form',
  applicationAdditionalInfoDocUpload: 'api/cn/application-form/document',
  getApplicationAdditionalInfo: 'api/cn/application-form',
  additionalInfoDocDelete: 'api/cn/application-form/delete',

  getCSRFToken: 'api/csrf-token',
  verifyCSRFToken: `api/verify-csrf-token`,

  getAdminApplication: 'api/admin/cn/applications',
  getCityAdminList: 'api/admin/city-admin',
  addCityAdmin: 'api/admin/city-admin',
  saveAddress: 'api/admin/upload/address',
  addStaffByCityAdmin: 'api/admin/staff',
  getCityAdminStaffList: 'api/admin/staff',
  updateCityAdmin: 'api/admin/city-admin',
  adminApplicationById: 'api/admin/cn/application/',
  postSubmissionReview: 'api/admin/cn/clerk-submission-review',
  voidSubmissionReview: 'api/admin/cn/clerk-submission-review/void',
  applicationUpdateByAdmin: 'api/admin/cn/application/update',
  postDecision: 'api/admin/cn/decision',
  voidDecision: 'api/admin/cn/decision/void',
  submitNotes: 'api/admin/cn/notes',
  postEmail: 'api/admin/cn/send-mail',
  deleteNote: 'api/admin/cn/notes/delete',
  postPayment: 'api/admin/cn/add/fee',
  waiveOffFee: 'api/admin/cn/waive-off/fee',
  managerProfileUploadSignature: 'api/admin/city-admin/profile',
  cityAdminStaffResetPassword: 'api/admin/staff/password/create',
  addLicenseFee: 'api/admin/add-license-fee',
  updateLicenseFee: 'api/admin/update-license-fee',
  deleteLicenseFee: 'api/admin/remove-license-fee',
  deleteOwnerOfficerAdmin: 'api/admin/cn/ownership/remove',
  editOwnerOfficerAdmin: 'api/admin/cn/ownership/update',
  getCityAdminById: 'api/admin/staff-details',
  sendApplicationAdditionalInfoAdmin: 'api/admin/send-application-form-link',
  getAdminPaymentList: 'api/admin//cn/payment-history',
  getExportDataFile: '/api/admin/export-payment-history/Excel',
  verifyCSRFTokenAdmin: `api/admin/verify-csrf-token`,
  sendOtpProfile:'api/admin/send-profile-phone-otp',
  verifiyOtpProfileadmin:'api/admin/verify-profile-otp',
  emailResendVerificationadmin: "api/admin/resend-email-link",
  renewPermit:'api/renew-permit',

}
